<template>
  <div>
    <div
      class="row"
      v-show="!isLoading.methods && methods.length === 0"
    >
      <div class="flex xs12">
        <h5>{{ $t('regions.tabs.methods.nothing_to_add') }}</h5>
      </div>
    </div>
    <div class="row">
      <div
        class="flex xs12 sm6 md4"
        v-for="method in methods"
        :key="method.id"
      >
        <va-checkbox
          :label="$t(method.name)"
          :disabled="loading || isLoading.methods"
          v-model="method.inRegion"
        />
      </div>
    </div>
    <div
      class="row"
      v-show="methods.length > 0"
    >
      <div class="flex xs12">
        <va-button
          color="primary"
          type="button"
          :disabled="loading || isLoading.methods"
          @click="submit"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'change-method',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    region: {
      type: Object,
      required: false,
      default: function () { return {} },
    },
  },
  data () {
    return {
      isLoading: {
        methods: false,
      },
      methods: [],
    }
  },
  watch: {
    region (val) {
      if (val.methods && this.methods.length !== 0) {
        this.methods = this.fixData(this.methods)
      }
    },
  },
  created () {
    this.getMethods()
  },
  methods: {
    fixData (data) {
      const methods = []
      for (const d of data) {
        const method = {
          id: d.id,
          name: d.name,
          inRegion: false,
        }
        method.inRegion = this.methodIn(d.id)
        methods.push(method)
      }
      return methods
    },
    methodIn (methodId) {
      if (!this.region || !this.region.evangelistic_methods) return false
      const isIn = this.region.evangelistic_methods.find(g => g.id === methodId)

      return isIn !== undefined
    },
    async getMethods () {
      this.isLoading.methods = true

      let u = false
      try {
        u = await this.$http.get('evangelistic-methods')
      } catch (err) {
        // console.log('Error fetching region data', err)
        this.isLoading.methods = false
        return
      }

      this.isLoading.methods = false
      this.methods = this.fixData(u.data.data)
    },
    async submit () {
      const isValid = this.methods.some(g => g.inRegion === true)
      if (!isValid) return

      const methods = this.methods.filter(g => g.inRegion)
      const regionMethods = {
        id: this.region.id,
        evangelistic_methods: {
          _ids: methods.map(g => g.id),
        },
      }

      this.$emit('submit', regionMethods)
      console.log('Submit!', regionMethods)
    },
  },
}
</script>
